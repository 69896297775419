
#Footer3Icon {
    width: 100%;
    height: 30vw;
    padding-bottom: 5vw;
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 10vw;
    a {
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {
            width: 12vw;
            -webkit-filter: drop-shadow(0px 0px 5px #0000007e);
            filter: drop-shadow(0px 0px 5px #0000007e);          
        }
    }
}

@media (min-width: 1366px) {
    
    #Footer3Icon {
        width: 100%;
        height: 7vw;
        padding-bottom:1vw;
        display: flex;
        justify-content: center;
        text-align: center;
        gap: 3vw;
        a {
            width: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            img {
                width: 2vw;
            }
        }
    }
       
}