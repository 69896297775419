@import url("https://use.typekit.net/rbg7xif.css");
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  outline: none;
}

//COLOR MODS
$Pink: #ffb6e7;
$Green: #002800;

#loader {
  display: flex;
  position: fixed;
  top: 0vh;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url(./assets/img/decoration/backgrounds/bigBackgroundPink.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 999999;
  // animation: noLoader 1s 3s forwards;
  svg {
    width: 50vw;
    height: 50vw;
    animation: Spin 3s 1.8s ease-in;
  }
  #Peddle1 {
    opacity: 0%;
    animation: display 0.2s 0.2s forwards;
  }
  #Peddle2 {
    opacity: 0%;

    animation: display 0.2s 0.3s forwards;
  }
  #Peddle3 {
    opacity: 0%;

    animation: display 0.2s 0.4s forwards;
  }
  #Peddle4 {
    opacity: 0%;

    animation: display 0.2s 0.5s forwards;
  }
  #Peddle5 {
    opacity: 0%;

    animation: display 0.2s 0.6s forwards;
  }
  #Peddle6 {
    opacity: 0%;

    animation: display 0.2s 0.7s forwards;
  }
  #Peddle7 {
    opacity: 0%;

    animation: display 0.2s 0.8s forwards;
  }
  #Peddle8 {
    opacity: 0%;

    animation: display 0.2s 0.9s forwards;
  }
  #Peddle9 {
    opacity: 0%;

    animation: display 0.2s 1s forwards;
  }
  #Peddle10 {
    opacity: 0%;

    animation: display 0.2s 1.1s forwards;
  }
  #Peddle11 {
    opacity: 0%;

    animation: display 0.2s 1.2s forwards;
  }
  #Peddle12 {
    opacity: 0%;

    animation: display 0.2s 1.3s forwards;
  }
  #Peddle13 {
    opacity: 0%;

    animation: display 0.2s 1.4s forwards;
  }
  #Peddle14 {
    opacity: 0%;

    animation: display 0.2s 1.5s forwards;
  }
  #Peddle15 {
    opacity: 0%;

    animation: display 0.2s 1.6s forwards;
  }
  #Peddle16 {
    opacity: 0%;

    animation: display 0.2s 1.7s forwards;
  }

  @keyframes display {
    100% {
      opacity: 100%;
    }
  }
  @keyframes Spin {
    100% {
      transform: rotate(3000deg);
    }
  }
}
.Loaded {
  animation: PageAnimation 1s forwards ease-in;
  pointer-events: none;
  opacity: 0%;

  @keyframes PageAnimation {
    0% {
      opacity: 100%;
    }

    100% {
      opacity: 0%;
    }
  }
}

.Christmas {
  #Card {
    background-image: url(./assets/img/decoration/backgrounds/ChristmasBackground.png);
  }
}

#SnowTop {
  position: absolute;
  width: 104vw;
  left: 50%;
  top: -12vw;
  transform: translateX(-50%);
  z-index: 1;
  -webkit-filter: drop-shadow(0px 5px 5px #0000007e);
  filter: drop-shadow(0px 5px 5px #0000007e);
}

#DisplayNone {
  display: none;
}

#Card {
  position: relative;
  background-image: url(./assets/img/decoration/backgrounds/BackgroundV2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  outline: 4px solid;
  outline-offset: -3vw;
  width: 100vw;
  height: 100%;
  padding-top: 1px;
  animation: none;
  overflow: hidden;
}

section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 10vw;

  &#Error {
    height: 100vh;
    header {
      height: 100vh;
      position: relative;
      h1 {
        font-size: 10vw;
        position: absolute;
        color: $Pink;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .Padding {
    padding-bottom: 4vw;
  }
  header {
    h1 {
      font-family: industry-inc-base, sans-serif;
      padding: 6vw 0 3vw;
      font-size: 6vw;
      line-height: 1.3;
      &#PageTitle {
        padding: 0vw 0 0;
        font-size: 8vw;
        line-height: 1.3;
        text-transform: uppercase;
        font-family: industry-inc-base, sans-serif;
      }
      &#TOSTitle {
        padding: 5vw 0 0;
        font-size: 8.5vw;
        line-height: 1.3;
        text-transform: uppercase;
        font-family: industry-inc-base, sans-serif;
      }
    }

    h5 {
      display: none;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      opacity: 50%;
      top: 15px;
    }
    strong {
      font-size: 6vw;
      line-height: 1.6;
      text-align: center;
    }
    p {
      font-size: 5vw;
      line-height: 1.5;
    }
    .Extra {
      line-height: 2;
    }
    small {
      font-size: 4.5vw;
      line-height: 1.5;
    }

    a {
      display: block;
      width: 100%;
    }
  }
  hr {
    margin: 5vw 0;
    background-color: $Pink;
    border-top: 1px solid;
  }
  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .ProductWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
      display: block;
      position: relative;

      .PortImg {
        width: 100%;
        height: auto;
        -webkit-filter: drop-shadow(0px 0px 10px #0000007e);
        filter: drop-shadow(0px 0px 10px #0000007e);
      }
      .PortImgNoShadow {
        width: 100%;
        height: auto;
        -webkit-filter: drop-shadow(0px 0px 10px #0000007e);
        filter: drop-shadow(0px 0px 10px #0000007e);
      }
      .PortVideo {
        width: 100%;
        height: auto;
        -webkit-filter: drop-shadow(0px 0px 10px #0000007e);
        filter: drop-shadow(0px 0px 10px #0000007e);
      }

      figcaption {
        width: 100%;
        small {
          padding: 3vw 0 6vw;
          font-size: 3.2vw;
          line-height: 0.5;
          text-align: right;
          float: right;
          a {
            color: $Pink;
            font-family: industry-inc-base, sans-serif;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .Padding {
      padding-bottom: 5vw;
    }
    strong {
      padding: 3vw 0;
      font-size: 6vw;
      line-height: 1.3;
      text-align: left;
    }
    p {
      padding: 3vw 0;
      font-size: 5vw;
      line-height: 1.3;

      &.AboutText {
        text-align: left;
      }
      &#TosText1 {
        padding: 5vw 0 0;
        font-size: 4vw;
      }
      &#TosText2 {
        padding: 5vw 0 2vw;
        font-size: 4vw;
      }
    }
    ul {
      padding: 5vw 0vw;
      li {
        font-size: 8vw;
        font-weight: bold;
        line-height: 1.5;
        font-family: industry-inc-base, sans-serif;
      }
      &#TOSText {
        padding: 2vw 0vw;
        li {
          text-align: left;
          font-size: 4vw;
          font-weight: 400;
          line-height: 1.5;
          font-family: "Roboto", sans-serif;
        }
      }
    }
    #HypeFace {
      width: 75%;
      margin: auto;
      padding: 6vw 0;
    }
    #HappyFace {
      width: 90%;
      margin: auto;
      padding: 6vw 0;
    }
    button {
      background-color: $Pink;
      margin-top: 3vw;
      padding: 3vw 5vw 1vw;
      border-radius: 50px;
      border: 3px solid $Pink;

      color: $Green;
      font-size: 5vw;
      font-family: industry-inc-base, sans-serif;
      font-weight: 400;
      font-style: normal;
    }
    .ChristmasButton {
      button {
        background-color: white;
        border: 3px solid white;
      }
    }
    span {
      padding-bottom: 6vw;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      #FormWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6vw;
        padding-bottom: 5vw;
      }

      .InputWrapper,
      .InputWrapperError,
      .TextareaError {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 100%;

        &.InputWrapperError,
        &.TextareaError {
          display: flex;
          flex-direction: column;
          align-items: start;
          width: 100%;
          input,
          textarea {
            border: solid 3px red;
          }
        }

        input,
        textarea {
          width: 100%;
          padding: 3vw;
          border-radius: 3vw;
          resize: none;

          font-size: 3.5vw;

          &:focus {
            outline: 3px solid $Pink;
          }
          &:hover {
            outline: 3px solid $Pink;
          }
        }
      }
      span {
        padding: 0;
        line-height: 2;
        color: red;
        font-size: 4vw;
        font-weight: 600;
      }

      button {
        background-color: $Pink;
        margin: 0;
        padding: 3vw 5vw 1vw;
        border-radius: 50px;
        border: 3px solid $Pink;
        width: 30vw;
        color: $Green;
        font-size: 5vw;
        font-family: industry-inc-base, sans-serif;
        font-weight: 400;
        font-style: normal;
      }

      #noShow {
        display: none;
        margin-top: 5vw;
      }
      #Show {
        margin-top: 5vw;
        font-size: 15vw;
      }
    }
  }
}

@media (min-width: 1366px) {
  #loader {
    svg {
      width: 10vw;
      height: 10vw;
    }
  }

  body {
    background-image: url(./assets/img/decoration/backgrounds/bigDarkBackground.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  #Space {
    padding-bottom: 10vw;
  }

  #SnowTop {
    width: 42vw;
    top: -5vw;
  }

  #Card {
    outline-offset: -0.5vw;
    width: 40vw;
    height: 100%;
    top: 5vw;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0.5vw;
    box-shadow: 0px 0px 31px 8px rgba(0, 0, 0, 0.1);
    overflow: visible;
  }
  section {
    padding: 0 3vw;

    &#Error {
      header {
        h1 {
          font-size: 2vw;
        }
      }
    }
    .Padding {
      padding-bottom: 1vw;
    }
    header {
      h1 {
        padding: 2vw 0 1vw;
        font-size: 1.5vw;
        &#PageTitle {
          font-size: 2vw;
        }
        &#TOSTitle {
          padding: 2vw 0 0;
          font-size: 2vw;
        }
      }

      strong {
        font-size: 2vw;
        line-height: 1.6;
      }
      p {
        font-size: 1.3vw;
      }
      small {
        font-size: 1vw;
      }
    }
    hr {
      margin: 1vw 0;
    }
    main {
      .ProductWrapper {
        .PortImgNoShadow {
          padding-top: 1vw;
          width: 60%;
          height: auto;
        }

        figcaption {
          small {
            padding: 1vw 0 2vw;
            font-size: 1vw;
          }
        }
      }
      .Padding {
        padding-bottom: 1vw;
      }
      strong {
        padding: 1vw 0;
        font-size: 1.5vw;
      }
      p {
        padding: 0.5vw 0;
        font-size: 1vw;
        line-height: 1.5;

        &#TosText1 {
          padding: 1vw 0 0;
          font-size: 1vw;
        }
        &#TosText2 {
          padding: 1vw 0 0vw;
          font-size: 1vw;
        }
      }
      ul {
        padding: 0.5vw 0vw;
        li {
          font-size: 1.5vw;
        }
        &#TOSText {
          padding: 1vw 0vw;
          li {
            font-size: 1vw;
          }
        }
      }
      #HypeFace {
        width: 40%;
        padding: 1vw 0;
      }
      #HappyFace {
        width: 50%;
        padding: 1vw 0;
      }
      button {
        margin-top: 1vw;
        padding: 1vw 2vw 0.7vw;

        font-size: 1vw;
      }
      span {
        padding-bottom: 2vw;
      }

      form {
        margin: auto;

        #FormWrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          flex-direction: column;
          gap: 2vw;
          padding-bottom: 2vw;
        }

        .InputWrapper,
        .InputWrapperError,
        .TextareaError {
          padding: 0;
          &.InputWrapperError,
          &.TextareaError {
            input,
            textarea {
              border: solid 3px red;
            }
          }

          input,
          textarea {
            padding: 1vw;
            border-radius: 0.5vw;

            font-size: 0.8vw;
          }
        }
        span {
          font-size: 0.8vw;
        }

        button {
          padding: 1vw 2vw 0.7vw;
          width: 7vw;
          font-size: 1vw;
        }

        #noShow {
          margin-top: 3vw;
        }
        #Show {
          margin-top: 2vw;
          font-size: 5vw;
        }
      }
    }
  }
}

// Her starter alt det her sne kaos
// https://codepen.io/alphardex/pen/dyPorwJ
@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

.snowFlake {
  $total: 300;
  position: fixed;
  top: 0vw;
  width: 1vw;
  height: 1vw;
  background-image: url(./assets/img/decoration/Flake.png);
  background-repeat: no-repeat;
  background-size: cover;

  @for $i from 1 through $total {
    $random-x: random(1000000) * 0.0001vw;
    $random-offset: random_range(-100000, 100000) * 0.0001vw;
    $random-x-end: $random-x + $random-offset;
    $random-x-end-yoyo: $random-x + ($random-offset / 2);
    $random-yoyo-time: random_range(30000, 80000) / 100000;
    $random-yoyo-y: $random-yoyo-time * 100vh;
    $random-scale: random(10000) * 0.0001;
    $fall-duration: random_range(10, 30) * 1s;
    $fall-delay: random(30) * -1s;
    &:nth-child(#{$i}) {
      opacity: random(10000) * 0.0001;
      transform: translate($random-x, -10px) scale($random-scale);
      animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
    }
    @keyframes fall-#{$i} {
      #{percentage($random-yoyo-time)} {
        transform: translate($random-x-end, $random-yoyo-y) scale($random-scale)
          rotate(-360deg);
      }
      to {
        transform: translate($random-x-end-yoyo, 100vh) scale($random-scale)
          rotate(360deg);
      }
    }
  }
}
