#ClassicLogo {
    margin-top: 10vw;
    width: 70vw;
    z-index: 5;
    cursor: pointer;
}
#SmolLogo {
    margin-top: 10vw;
    width: 40vw;
    z-index: 5;
    cursor: pointer;
}
.Bt{
    padding-top: 3vw;
    width: 100%;
    cursor: pointer;
    -webkit-filter: drop-shadow(0px 0px 5px #0000007e);
    filter: drop-shadow(0px 0px 5px #0000007e);
  }


@media (min-width: 1366px) {
    #ClassicLogo {
        margin-top: 3vw;
        width: 15vw;
    }
    #SmolLogo {
        margin-top: 3vw;
        width: 10vw;
    }
    .Bt{
        padding-top: 2vw;
        width: 50%;
        
    }
        
}